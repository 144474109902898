import React, { Component } from "react";
import "./App.css";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";

class App extends Component {
  render() {
    return (
      <div>
        <br/>

        
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a
            className="navbar-brand col-sm-3 col-md-2 mr-0"
            href="http://smartlaw.freeweb3.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Web3Skills-SmartLaw in a Metaverse
          </a>
          <br/>
        <br/>
        
        </nav>
        <div className="container-fluid mt-5 ml-5">
          <div className="row">
             
             
                <Grid item md={12}>

                    <h1>Web3Skills-SmartLaw in a Metaverse.</h1>



<ReactPlayer
  url="https://freeweb3.infura-ipfs.io/ipfs/QmNXLzMcr291fBtsbN4192VR6AfH6rzKYP8tBZu6FA6E6c"
  controls
  width="640"
  height="360"
/>



                    <br/>
                    <a
                      className="App-link"
                      href="http://freeweb3.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Web3Skills{" "}
                      <u>
                        <b>SmartLaw</b>
                      </u>{" "}
                      Metaverse
                    </a>
             
              </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
